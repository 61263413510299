import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { resetCart } from "../../store/reducers/cart-slice";
import Button from 'react-bootstrap/Button';
import BestReviewedCard from "../../best-reviewed-card";
import CheckoutProduct from "../../checkout-product";
import Loader from "../../loader";
import { getSuggestedItems, placeOrder } from "../../../services/api/api-actions";
import { setIsReqLogin } from "../../../store/reducers/user-reducer";
import { UTILS } from "../../../utils";
import cartSlice, { setCart, resetCart } from "../../../store/reducers/cart-slice";
import PaymentComponent from "../payment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./checkout.css";

const CheckoutSidebar = ({ show, setShow, onNextClick }) => {
  const { cart, user } = useSelector((s) => s);
  const [remainingCart, setRemainingCart] = useState([]);
  const dispatch = useDispatch();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderLoading, setOrderLoading] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState("0");
  const [distance, setDistance] = useState("1");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const navigate = useNavigate();
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [activeShop, setActiveShop] = useState("");
  const [selectedShopId, setSelectedShopId] = useState(null);



  const handleOpen = () => setShowPaymentModal(true);
  const handleClose = () => setShowPaymentModal(false);

  const handleStripePay = () => {
    setPaymentSuccess(true);
    console.log("Payment was successful!");
  };

  const handleResetCart = () => {
    // Close the sidebar after placing the order
    setShow(false);
    dispatch(resetCart());
    toast.info("Your cart reset successfully");
  };

  const handlePaymentForm = () => {
    console.log("Next button clicked")
    setShowPaymentModal(true);
  }

  const handleBack = () => {
    // Reset active shop to show all stores in the cart
    setActiveShop("");
    setSelectedShopId(null); // Clear selected shop ID
  };


  const placeOrder = async (data) => {
    try {
      console.log("Placing order with data:", data);
      const token = localStorage.getItem('@token');

      // Place order request
      const response = await axios.post(
        `https://hamiddawod-001-site2.htempurl.com/api/v1/Order/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Order placed successfully, response:", response.data.data);

      // Fetch order details
      const orderRes = await axios.get(
        `https://hamiddawod-001-site2.htempurl.com/api/v1/Order/${response.data.data}`
      );
      const orderDetails = orderRes.data.data;
      console.log("Order details fetched:", orderDetails);

      const remainingCart = cart.cart.filter(item => item.vendorShopId !== data.shopId);
      setRemainingCart(remainingCart);

      // Payment handling
      if (orderDetails.paymentMethod === "card") {
        console.log("Payment method is card, showing payment modal...");
        setShowPaymentModal(true);
        // setSelectedShopId(data.shopId);
        setSelectedPaymentMethod("card");

        const payData = {
          id: orderDetails.id,
          paymentMethod: orderDetails.paymentMethod,
          totalAmount: orderDetails.totalAmount,
          discount: orderDetails.discount,
          sourceToken: '',
          couponCode: '',
        };

        const payRes = await axios.post(
          'https://hamiddawod-001-site2.htempurl.com/api/v1/Order/UpdatePayment/',
          payData
        );
        console.log("Payment update response:", payRes.data);

        if (payRes.status === 200) {
          console.log("Payment processed successfully, closing modal...");
          setIsModalClosed(true);
          console.log("isModalClosed : ", isModalClosed)
        }

      } else {
        console.log("Payment method is cash on delivery");
        setSelectedPaymentMethod("cashOnDelivery");
        // dispatch(setCart([]));
        // Remove items from the cart that belong to the specific store
        dispatch(setCart(cart.cart.filter(item => item.vendorShopId !== data.shopId)));
        navigate("/order-history")
      }
      // Close the sidebar after placing the order
      setShow(false);
      toast.success("Your order placed successfully");

    } catch (error) {
      console.error("Error placing order or processing payment:", error);
      toast.error("There was an issue processing your order. Please try again.");
    }
  };

  // const onPlaceOrder = async (data) => {
  //   try {
  //     setOrderLoading(true);
  //     setShowPaymentModal(true)
  //     // handlePaymentForm();
  //     const res = await placeOrder(data);
  //     // dispatch(setCart([]));
  //     //set checkout sidebar false
  //     onNextClick(res);
  //     try {
  //       const response = await axios.get(`https://hamiddawod-001-site2.htempurl.com/api/v1/Order/${res.data}`);
  //       console.log("Order details", response.data.data);
  //       if (response.data.data.paymentMethod === "card") {
  //         console.log("payment method is ", response.data.data.paymentMethod)
  //         setShowPaymentModal(true);
  //         // handlePaymentForm();
  //         setSelectedPaymentMethod("card")
  //       }
  //       else
  //       {
  //         setSelectedPaymentMethod("cashOnDelivery")
  //       }
  //     } catch (error) {
  //       console.log("Error fetching order details", error);
  //     }


  //     console.log("payment method is = ", selectedPaymentMethod);

  //     return selectedPaymentMethod;

  //     // const payData = {
  //     //   "id": res.data,
  //     //   "paymentMethod": data.paymentMethod,
  //     //   "totalAmount": data.totalAmount,
  //     //   "discount": data.discount,
  //     //   "sourceToken": '',
  //     //   "couponCode": '',
  //     // }

  //     // await axios.post('https://hamiddawod-001-site2.htempurl.com/api/v1/Order/UpdatePayment/', payData);

  //   } catch (error) {
  //     console.log("Error placing order", error);
  //     if (error?.message === "Request failed with status code 401") {
  //       dispatch(setIsReqLogin(true));
  //     } else {
  //       alert(UTILS?.returnError(error));
  //     }
  //   } finally {
  //     setOrderLoading(false);
  //   }
  // };

  useEffect(() => {
    const getRelatedItems = async () => {
      setLoading(true);
      console.log("shop id and name : : ", activeShop, selectedShopId);
      try {
        // Filter cart items where vendorShopId matches the selectedShopId
        const filteredCartItems = cart?.cart?.filter((item) => item.vendorShopId === selectedShopId);
        
        // If there are no items for the selected shop, stop further execution
        if (!filteredCartItems?.length) return;
        
        // Map over the filtered cart items to get their ids
        const cartItemIds = filteredCartItems.map((item) => item.id);
  
        console.log("Filtered Cart Item IDs for Shop:", cartItemIds);
  
        const cartItemIdsStr = cartItemIds.join(",");  // Convert array to a comma-separated string
        const res = await getSuggestedItems(cartItemIds);
        // console.log("API Response for suggested items:", res?.data);
  
        // Filter related products based on vendorShopId
        const filteredRelatedProducts = res?.data.filter((item) => item.vendorShopId === selectedShopId);
  
        console.log("Filtered related products:", filteredRelatedProducts);
        setRelatedProducts(filteredRelatedProducts);
      } catch (error) {
        console.log("Error fetching related items", error);
        alert(UTILS.returnError(error));
      } finally {
        setLoading(false);
      }
    };
  
    const getDeliveryCharges = async () => {
      try {
        const vendorShop = cart?.cart?.find((item) => item.vendorShopId === selectedShopId)?.vendorShop;
        if (!vendorShop) return;
  
        const res = await UTILS.getDistance(
          user?.location?.latitude,
          vendorShop?.latitude,
          user?.location?.longitude,
          vendorShop?.longitude
        );
  
        setDistance(res);
        setDeliveryCharges((res || 1) * user?.vehicle?.perKmRate);
      } catch (error) {
        console.log("Error fetching delivery charges", error);
      }
    };
  
    if (cart?.cart?.length && selectedShopId) {
      getDeliveryCharges();
      getRelatedItems();
    }
  }, [cart?.cart, selectedShopId, user]);
  

  // const total = cart?.cart?.reduce(
  //   (res, item) => (res += (item?.discountedPrice || item?.price) * item?.qty),
  //   0
  // );

  // const totalWithoutDiscount = cart?.cart?.reduce(
  //   (res, item) => (res += item?.price * item?.qty),
  //   0
  // );

  // const totalDiscount = totalWithoutDiscount - total;

  //new

  // Filter cart items by the selected shop
  const selectedStoreCartItems = cart?.cart?.filter(
    (item) => item.vendorShop?.id === selectedShopId
  );

  // Calculate total, total without discount, and discount for the selected store
  const total = selectedStoreCartItems?.reduce(
    (res, item) => (res += (item?.discountedPrice || item?.price) * item?.qty),
    0
  );

  const totalWithoutDiscount = selectedStoreCartItems?.reduce(
    (res, item) => (res += item?.price * item?.qty),
    0
  );

  const totalDiscount = totalWithoutDiscount - total;

  const placeCashOrder = () => {
    console.log("Placing order with Cash");
  };

  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const groupedCartItems = cart?.cart?.reduce((acc, item) => {
    if (!item.vendorShop || !item.vendorShop.name) {
      return acc; // Skip items with no vendorShop or name
    }
    const shopName = item.vendorShop.name;
    const shopImage = item.vendorShop.image;
    console.log("shop details : ", item.vendorShop)

    if (!acc[shopName]) {
      acc[shopName] = {
        shopDetails: item.vendorShop, // Storing the vendor shop details
        products: [], // To hold the products for this vendor
      };
    }

    acc[shopName].products.push(item); // Push the product to the respective vendor group
    return acc;
  }, {});

  const handleContinueShopping = (shopId, shopName) => {
    // Navigating to the vendor's product page, assuming the URL is `store-dashboard/:vendorId`
    // navigate(`/store-dashboard/${vendorId}`);
    // window.location.reload();
    console.log("store is selected with Id :: ", shopId)
    setSelectedShopId(shopId); // Track the shop being continued
    setActiveShop(shopName);
  };

  return (
    <div className={`checkout-sidebar ${show ? 'show' : ''}`}>
      <div className="checkout-header">
        <button onClick={() => setShow(false)} className="closeBtn">
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        {/* <span>
          <h1 className="custom-header_title">
            {cart?.cart[0]?.vendorShop?.name}
          </h1>
          {cart?.cart[0] && (
            <h3 className="custom-header_address">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {cart?.cart[0]?.vendorShop?.location}
            </h3>
          )}
        </span> */}
      </div>
      <hr />
      {cart?.cart?.length ? (
        <div className="Checkout-modal-wrapper">
          {!activeShop ? (
            <div className="card-container">
              {/* just try */}

              {groupedCartItems && Object.keys(groupedCartItems).map((shopName) => (
                <div key={shopName} className="vendor-group">
                  {/* Display Vendor Shop Details */}
                  <span className="p-2">
                    <h1 className="custom-header_title">
                      {groupedCartItems[shopName]?.shopDetails?.name}
                    </h1>
                    <img
                      src={groupedCartItems[shopName]?.shopDetails?.image}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "30px",
                      }}
                    />
                    <h3 className="custom-header_address">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      {groupedCartItems[shopName]?.shopDetails?.location}
                    </h3>
                  </span>

                  {/* Display Products for this Vendor */}
                  <div className="card-container">
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      {groupedCartItems[shopName]?.products?.map((item, index) => (
                        <div key={index}>
                          <img
                            src={item?.imagePath}
                            style={{
                              height: "84px",
                              objectFit: "cover",
                              border: "1px solid rgb(255, 90, 0)",
                              borderRadius: "50%",
                              padding: "10px",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      className="mt-4"
                      style={{
                        backgroundColor: "#ff5a00",
                        border: "none",
                        position: "relative",
                        marginLeft: "35%",
                      }}
                      onClick={() =>
                        handleContinueShopping(
                          groupedCartItems[shopName]?.shopDetails?.id,
                          // groupedCartItems[shopName]?.shopDetails?.name
                          shopName
                        )
                      }
                    >
                      Continue shopping
                    </Button>
                    <hr></hr>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div className="store-details">
                <h1>{groupedCartItems[activeShop]?.shopDetails?.name}</h1>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  {groupedCartItems[activeShop]?.products?.map((item, index) => (
                    <CheckoutProduct item={item} key={index} setShow={setShow} />
                  ))}
                </div>
              </div>
              <div className="col-md-12 me-2 d-flex align-items-center justify-content-between">
            <div className="gift-order mt-4">
              <h2>
                <i
                  className="fa fa-gift"
                  aria-hidden="true"
                  style={{ color: "#ff5a00" }}
                ></i>{" "}
                Make this order a gift
              </h2>
            </div>
            <div className="check-box mt-4">
              <input type="checkbox" style={{ accentColor: "#ff5a00" }} />
            </div>
          </div>
              {relatedProducts?.length ? (
                <div className="mb-0 ms-0 me-0" style={{ marginTop: "11px" }}>
                  <h2
                    className="suggestion-title"
                    style={{ color: "#ff5a00" }}
                  >
                    Suggested items
                  </h2>
                  <h3 className="suggestion-line">
                    Add these top picks to your order
                  </h3>
                  {loading ? (
                    <Loader style={{ height: "200px" }} />
                  ) : (
                    <div className="card-container">
                      <Slider
                        dots={false}
                        infinite={false}
                        speed={500}
                        slidesToShow={2}
                        slidesToScroll={1}
                        className="card-slider"
                        responsive={[
                          { breakpoint: 1200, settings: { slidesToShow: 2 } },
                          { breakpoint: 992, settings: { slidesToShow: 2 } },
                          { breakpoint: 768, settings: { slidesToShow: 2 } },
                          { breakpoint: 480, settings: { slidesToShow: 1 } },
                        ]}
                      >
                        {relatedProducts?.map((item, index) => (
                          <BestReviewedCard key={index} item={item} />
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              ) : null}
              {/* // Conditional rendering */}
              {selectedShopId === groupedCartItems[activeShop]?.shopDetails?.id && (
                <div>
                  {/* Billing details, payment method, and next button */}
                  <div
                    className="billing-detail position-relative"
                    style={{ marginTop: "25px" }}
                  >
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr className="no-border billing-row mb-2">
                          <td><h2 className="billing-title">Billing Detail</h2></td>
                        </tr>
                        <tr style={{ marginTop: "40px" }}>
                          <td>Total without Discount</td>
                          <td>$ {totalWithoutDiscount}</td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td>$ {Math.trunc(totalDiscount * 100) / 100}</td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td>$ 0</td>
                        </tr>
                        <tr className="no-border">
                          <td>Delivery Charges</td>
                          <td>
                            <span className="EstimateCost">Estimated Cost </span> $
                            {deliveryCharges}
                          </td>
                        </tr>
                        <tr className="no-border">
                          <td>Total Cost</td>
                          <td className="highlighted">$ {total + deliveryCharges * 1}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="payment-card">
                    <h3>Select Payment Method</h3>
                    <div className="payment-option">
                      <input
                        type="radio"
                        id="cashOnDelivery"
                        name="paymentMethod"
                        value="cashOnDelivery"
                        checked={selectedPaymentMethod === "cashOnDelivery"}
                        onChange={handlePaymentChange}
                      />
                      <label htmlFor="cashOnDelivery">Cash on Delivery</label>
                    </div>
                    <div className="payment-option">
                      <input
                        type="radio"
                        id="card"
                        name="paymentMethod"
                        value="card"
                        checked={selectedPaymentMethod === "card"}
                        onChange={handlePaymentChange}
                      />
                      <label htmlFor="card">Card</label>
                    </div>
                  </div>

                  {paymentSuccess ? (
                    <div className="success-message">
                      <h2>Thank you for your payment!</h2>
                      <p>Click Next to confirm your order</p>
                    </div>
                  ) : null}

                  <div style={{ marginTop: "25px" }}>
                    <Link
                      disabled={orderLoading || !selectedPaymentMethod}
                      to="#"
                      onClick={() => {
                        placeOrder({
                          totalAmount: total + deliveryCharges * 1,
                          shopId: cart?.cart[0]?.vendorShopId,
                          deliveryAddress: user?.location?.address,
                          latitude: user?.location?.latitude,
                          longitude: user?.location?.longitude,
                          orderItems: cart?.cart
                            ?.filter(item => item.vendorShopId === cart?.cart[0]?.vendorShopId)
                            ?.map((item) => ({
                              id: item?.id,
                              qty: item?.qty,
                              unitPrice: item?.discountedPrice || item?.price,
                              flavor: "",
                              size: "",
                            })),
                          paymentMethod:
                            selectedPaymentMethod === "cashOnDelivery"
                              ? "cashOnDelivery"
                              : "card",
                          distance: distance || "1",
                          description: "",
                          customerId: user?.userInfo?.id,
                          isPaid: selectedPaymentMethod === "card",
                          deliveryFee: 0,
                          statusId: 0,
                          preferenceName: "",
                          preferenceId: 0,
                          preferencePhone: "",
                        });
                      }}
                      className="element-custom-btn"
                    >
                      {orderLoading ? "Loading" : "Next"}
                    </Link>
                    <Link to="#" className="btn btn-secondary mt-2 mx-auto"
                      style={{ width: "100%" }}
                      onClick={handleResetCart}
                    >
                      Reset Cart
                    </Link>


                    <Link to="#" className="btn btn-dark mt-2 mx-auto"
                      style={{ width: "100%" }}
                      onClick={handleBack}
                    >
                      Go Back
                    </Link>

                    {showPaymentModal && (
                      <PaymentComponent
                        amount={total + deliveryCharges * 1}
                        onStripePay={handleStripePay}
                        showModal={showPaymentModal}
                        // shopId={selectedShopId}
                        remainingCart={remainingCart}
                        handleClose={handleClose}
                      />
                    )}
                  </div>
                </div>
              )}


            </div>
          )}
        </div>
      ) : (
        <div className="h-100 d-flex flex-column justify-content-center text-center">
          <span>You have no item in the cart</span>
        </div>
      )}

    </div>
  );
};

export default CheckoutSidebar;
