import React from "react";
import { useSelector } from "react-redux";
import { returnImage } from "../../utils";
import ProductCounter from "../counter";
import "./style.css";
import { useDispatch } from "react-redux";
import { setRemoveFromCart } from "../../store/reducers/cart-slice";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddInstruction from "../add-instruction";
const CheckoutProduct = ({ item, index, setShow }) => {
  // const { cart } = useSelector((s) => s);
  // Access the cart correctly
  const cart = useSelector((state) => state.cart.cart); // Adjust according to your state structure
  const dispatch = useDispatch();
  const onRemove = () => {
    // Dispatch the remove action
    dispatch(setRemoveFromCart({ id: item.id, size: item.size, color: item.color }));

    // Check if the cart is defined and is an array
    if (Array.isArray(cart)) {
      // Get the updated cart length after the removal
      const updatedCartLength = cart.length - 1; // Assuming the state will reflect this after the dispatch

      // Check if the cart is empty
      if (updatedCartLength === 0) {
        setShow(false); // Set the state in the parent component
      }
    } else {
      console.error("Cart is not an array:", cart);
    }

    toast.info("Your product removed successfully");
  };
  
  return (
    <>
      <div className="card-product">
        <div className="row m-0">
          <div className="col-md-4 d-flex flex-row align-items-center">
            <img
              src={returnImage(item?.imagePath)}
              style={{ height: "64px" }}
            />
          </div>
          <div className="col-md-6" style={{ textAlign: "left" }}>
            <div className="title_heading">
              <h2>{item?.name}</h2>
              <h3 style={{ textAlign: "left" }}>$ 3.49 / lb</h3>
              {item?.color &&
                <div
                className="color-circle mb-2 mt-2"
                style={{
                  backgroundColor: item?.color, // Assuming each color object has a `code` property for the color
                  width: '25px',  // Set the width of the circle
                  height: '25px', // Set the height of the circle
                  borderRadius: '50%', // Makes it a circle
                  marginRight: '10px', // Adds some spacing between circles
                }}
              ></div>
              }
              {/* <span className="">Size</span> */}
              {item?.size && 
                <div className="mb-3" style={{width:"100%", background:"#eee", border:"1px solid #FF5A00",padding:"3px 20px"}}>
                  <span className="mb-4" style={{position:"relative", textAlign:"left"}}>{item?.size}</span>
                </div>
              }
              
              <ProductCounter item={item} />
              <AddInstruction item={item} index={index} />
              {/* <Link to="#" className="add-instruction">
                <i className="fa fa-pencil" aria-hidden="true"></i> Add Instruction
              </Link> */}
            </div>
          </div>
          <div className="col-md-2 d-flex align-items-end justify-content-between flex-column">
            <i onClick={onRemove} className="fa fa-times" style={{ color: "#ff5a00", cursor: "pointer" }} aria-hidden="true"></i>
            <div className="checkout-price h-auto ">
              <span>${item?.discountedPrice || item?.price}</span>
              {/* <p className="mb-2">49</p> */}
            </div>
          </div>
          {/* <div className="col-md-12 d-flex align-items-center justify-content-between">
            <div className="gift-order mt-4">
              <h2>
                <i
                  className="fa fa-gift"
                  aria-hidden="true"
                  style={{ color: "#ff5a00" }}
                ></i>{" "}
                Make this order a gift
              </h2>
            </div>
            <div className="check-box mt-4">
              <input type="checkbox" style={{ accentColor: "#ff5a00" }} />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default CheckoutProduct;
