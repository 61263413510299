import React from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setInstructions } from "../../store/reducers/cart-slice";

function AddInstruction({ item }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  
  // Find the cart item by id, size, and color
  const cartItem = cart?.cart?.find(
    (x) => x?.id === item?.id && x?.size === item?.size && x?.color === item?.color
  );

  return (
    <div className="add-instruction-container">
      <div className={`input-container editing`}>
        <input
          className="ps-1"
          type="text"
          placeholder="Add Instruction"
          value={cartItem?.instructions || ""}
          onChange={(e) => {
            // Dispatch instruction with id, size, and color
            dispatch(setInstructions({ 
              value: e.target.value, 
              id: item.id, 
              size: item.size, 
              color: item.color 
            }));
          }}
        />
        <div className="edit-button">
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
}

export default AddInstruction;
