import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setAddToCart: (state, action) => {
      const newItem = action.payload;
      // const shopId = newItem.vendorShopId;

      // Check if any items in the cart have a different shopId
      // const differentShopExists = state.cart.some(
      //   (item) => item.vendorShopId !== shopId
      // );

      // Check if the newItem already exists in the cart
      // const itemExists = state.cart.find((item) => item.id === newItem.id);
      const itemExists = state.cart.find(
        (item) => 
          item.id === newItem.id &&
          item.size === newItem.size &&
          item.color === newItem.color
      );
      // if (differentShopExists) {
      //   // Throw an error or handle it as you prefer
      //   throw new Error("Items in the cart should be from the same shop.");
      // }
       if (itemExists) {
        // Throw an error or handle it as you prefer
        throw new Error(
          `This item already exists in the cart with quantity (${itemExists?.qty}).`
        );
      } else {
        state.cart.push({ ...newItem, qty: 1 });
      }
    },
    setRemoveFromCart: (state, action) => {
      const { id, size, color } = action.payload; // Destructure the id, size, and color from the payload
      const newCart = state.cart.filter(
        (item) => !(item?.id === id && item?.size === size && item?.color === color) // Check for id, size, and color
      );
      state.cart = newCart;
    },
    
    // setIncrementQtyCart: (state, action) => {
    //   const itemExists = state.cart.some(
    //     (item) => item.id === action.payload?.id
    //   );
    //   if (itemExists) {
    //     const newCart = state.cart.map((item) => ({
    //       ...item,
    //       qty: item?.id === action.payload?.id ? item?.qty + 1 : item?.qty,
    //     }));
    //     state.cart = newCart;
    //   } else {
    //     throw new Error("Please first add to cart");
    //   }
    // },

    setIncrementQtyCart: (state, action) => {
      const { id, size, color } = action.payload;
      
      // Check if an item with the same id, size, and color exists in the cart
      const itemExists = state.cart.some(
        (item) => item.id === id && item.size === size && item.color === color
      );
    
      if (itemExists) {
        // Increment the quantity of the matching item
        const newCart = state.cart.map((item) => {
          if (item.id === id && item.size === size && item.color === color) {
            return { ...item, qty: item.qty + 1 }; // Increment quantity
          }
          return item; // Return unchanged item
        });
        state.cart = newCart;
      } else {
        throw new Error("Please first add to cart");
      }
    },
    setDecrementQtyCart: (state, action) => {
      const { id, size, color } = action.payload;
    
      // Find the index of the item in the cart that matches the id, size, and color
      const itemIndex = state.cart.findIndex(
        (item) => item.id === id && item.size === size && item.color === color
      );
    
      if (itemIndex >= 0) {
        const currentItem = state.cart[itemIndex];
    
        // Check if the quantity is greater than 1
        if (currentItem.qty > 1) {
          // Decrement the quantity
          state.cart[itemIndex].qty -= 1;
        } else {
          // If quantity is 1, remove the item from the cart
          state.cart.splice(itemIndex, 1);
        }
      } else {
        throw new Error("Item not found in cart.");
      }
    },
    
    
    // setDecrementQtyCart: (state, action) => {
    //   const newCart = state.cart.map((item) => ({
    //     ...item,
    //     qty:
    //       item?.id === action.payload?.id && item?.qty > 1
    //         ? item?.qty - 1
    //         : item?.qty,
    //   }));
    //   state.cart = newCart;
    // },
    // setInstructions: (state, action) => {
    //   state.cart[action?.payload?.index].instructions = action?.payload?.value;
    // },
    setInstructions: (state, action) => {
      const { value, id, size, color } = action.payload;
    
      const itemIndex = state.cart.findIndex(
        (item) => item?.id === id && item?.size === size && item?.color === color
      );
    
      if (itemIndex >= 0) {
        state.cart[itemIndex].instructions = value; // Set the instruction for the specific item
      } else {
        throw new Error("Item not found in cart.");
      }
    },
    
    resetCart: (state, action) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCart,
  resetCart,
  setAddToCart,
  setRemoveFromCart,
  setIncrementQtyCart,
  setDecrementQtyCart,
  setInstructions,
} = cartSlice.actions;

// export const demoAsyncFun = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(demoAsync(amount))
//   }, 1000)
// }
export default cartSlice.reducer;
